import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, Input } from "@angular/core";
import {LoggerService} from "@services/logger-service.service";
import { AnimationLoader, AnimationOptions, LottieComponent, provideLottieOptions } from "ngx-lottie";

@Component({
  selector: "app-loader",
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  template: `
    <ng-lottie [width]="width" [height]="height" [options]="options"></ng-lottie>
  `,
  styleUrls: ["./loader.component.scss"],
  imports: [
    LottieComponent
  ],
  providers: [
    provideLottieOptions({
      player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
    }),
    AnimationLoader,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {

  @Input() width: string = "200px";
  @Input() height: string = "200px";

  options: AnimationOptions = {
    path: "./assets/animation/loader.json",
  };

  constructor(private logger: LoggerService) {
    this.logger.debug(this.constructor.name + " is created.");
  }
}
